import React from 'react'
import search from '../../../assets/images/cinefind/search.png'
import upcoming from '../../../assets/images/cinefind/upcoming.png'
import mobile1 from '../../../assets/images/cinefind/mobile1.png'
import mobile2 from '../../../assets/images/cinefind/mobile2.png'

export default () => {
  return (
    <>
      <figure className='project-section'>
        <p>
          This platform enables a user to access an archive database of relevant
          movies. Additionally because the database is constantly maintained and
          updated, each of the page categories are also updated. Finally all
          movies can be queried by movie name to fetch back results from the
          server to be displayed for the user. With the added ability to sort
          movies on the fly by title, release date, votes, and popularity –
          finding any movie just got easier.
        </p>
        <img src={search} alt='Search Page' />
      </figure>
      <figure className='project-section'>
        <p>
          The React library was chosen as the MVC for the view because of its
          popular and reusable component architecture that make up the platform
          as a whole. In addition, I wanted to be able to do partial page
          updates depending on which movie was selected from the carousel to
          fetch back only data specific to the movie's ID. Axios was the library
          used to fetch back the data from certain API endpoints depending on
          search query, category, or movie ID.
        </p>
        <p>
          The Sass pre-compiler library was chosen to quickly get the components
          styled with little overhead and to help keep the CSS DRY. Plus it
          allowed room for flexibility to create partials if needed for import
          to keep the project scalable if new pages or features are added.
        </p>
        <img src={upcoming} alt='Upcoming Movies Page' />
        <p>
          Rather than show an entire page of movie thumbnails and routing to a
          different page for each movie's information – I wanted the user to
          have an interactive experience with the 3d carousel (versus a flat
          carousel). The added bonus of this carousel enables a nice dramatic
          effect while perusing each movie's information. The inability to
          scroll through the movies quickly also enables the user to discover a
          movie that they might not have otherwise. Ultimately, the
          React-Coverflow library was used to implement the carousel feature.
          Partial page updates were done in order to reflect relevant movie
          information below the movie thumbnail when it gets selected without
          having to refresh the entire web page each time.
        </p>
      </figure>
      <figure className='project-section-stackathon'>
        <p>
          Here is the view for mobile. The navigation is kept simple without
          resorting to a burger menu. Additionally, the carousel mechanism
          continues to function the same way.
        </p>
        <img src={mobile1} alt='Mobile View' />
        <img className='stack-margin' src={mobile2} alt='Mobile View' />
      </figure>
    </>
  )
}
