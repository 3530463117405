import React, { Component } from 'react'
import logo from '../assets/logo/logo.svg'
import logoTeal from '../assets/logo/logo_teal.svg'
import { HashLink as Link } from 'react-router-hash-link-offset'
import smoothscroll from 'smoothscroll-polyfill'
import history from './history'

export default class Navbar extends Component {
  state = {
    active: false
  }
  // scrollToTop = () => {
  //   scroll.scrollToTop()
  // }

  toggleClass = () => {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  removeHash () {
    // Remove hash tag on page change and no reload required
    history.push(
      '',
      document.title,
      window.location.pathname + window.location.search
    )
  }

  componentDidMount () {
    // polyfill so behavior=smooth is compatible in all browsers for hash-link
    smoothscroll.polyfill()
  }

  render () {
    return (
      <nav className='nav' id='navbar'>
        <div className='nav-content'>
          {/* <p onClick={this.scrollToTop}>Mike Wong.</p> */}
          <a href='/'>
            <img
              src={logo}
              className='nav-logo'
              alt='Logo'
              onMouseOver={e => (e.target.src = logoTeal)}
              onMouseOut={e => (e.target.src = logo)}
              // onClick={this.scrollToTop}
            />
          </a>
          {/* Beginning of mobile nav components */}
          <button
            id='burger'
            className={
              'hamburger hamburger--spin' +
              (this.state.active ? ' is-active' : '')
            }
            type='button'
            onClick={this.toggleClass}
          >
            <span className='hamburger-box'>
              <span className='hamburger-inner' />
            </span>
          </button>{' '}
          <ul
            className={
              this.state.active
                ? 'nav-items-burger flex-display '
                : 'nav-items-burger'
            }
          >
            <li className='nav-item-burger'>
              <a onClick={this.toggleClass} href='/'>
                About
              </a>
            </li>
            <li className='nav-item-burger'>
              <Link onClick={this.toggleClass} to='/#work' scrollOffset={-70}>
                Work
              </Link>
            </li>
            <li className='nav-item-burger'>
              <Link
                onClick={this.toggleClass}
                to='/#contact'
                scrollOffset={-70}
              >
                Contact
              </Link>
            </li>
          </ul>
          {/* end of mobile nav components */}
          <ul className='nav-items'>
            <li className='nav-item'>
              <a href='/'>About</a>
            </li>
            <li className='nav-item' onClick={this.removeHash}>
              <Link to='/#work' scrollOffset={-70}>
                Work
              </Link>
            </li>
            <li className='nav-item' onClick={this.removeHash}>
              <Link to='/#contact' scrollOffset={-70}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}
