import React from 'react'
import stackathon_video from '../../../assets/videos/stackathon_video.m4v'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <figure className="project-section-stackathon">
        <p>Live demo or watch the video demo of the project below.</p>
        <VisitButtons
          demo={'https://expo.io/@mike_wong/Stackathon'}
          github={'https://github.com/mikekwong/stackathon'}
        />
        <video
          src={stackathon_video}
          type="video/mp4s"
          controls
          controlsList="nodownload"
        />
      </figure>
    </>
  )
}
