import React, { Component } from 'react'
import NavbarWork from '../../NavbarWork'
import Section from '../../Section.jsx'
import Content from './Content'
import Footer from '../../sections/Footer'
import Arrow from '../Arrow'
import CaseStudy from './CaseStudy'
import LearnMore from './LearnMore'

export default class Ngage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div>
        <NavbarWork />
        <Arrow navLeft='/work/stackathon' navRight='/work/prsm' />
        <Section title='ngage' copy={<Content />} dark id='ngage' />
        <Section title='case study' copy={<CaseStudy />} dark id='casestudy' />
        <Section title='learn more' copy={<LearnMore />} dark id='learnmore' />
        <Footer />
      </div>
    )
  }
}
