import React from 'react'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <p>
        A mobile app for iOS and Android. This single player game is a physics
        based puzzle game with two difficulties.
      </p>
      <ul className="bullets">
        <li>
          <h2>ROLE</h2>
          <p>Mobile Developer</p>
        </li>
        <li>
          <h2>TECHNOLOGY</h2>
          <p>
            React Native, React Navigation, CSS, Matter.js, Node.js, RNGE
            (Entity Component System).
          </p>
        </li>
        <li>
          <h2>TYPE</h2>
          <p>Mobile app</p>
        </li>
      </ul>
      <VisitButtons
        demo={'https://expo.io/@mike_wong/Stackathon'}
        github={'https://github.com/mikekwong/stackathon'}
      />
    </>
  )
}
