import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo/logo.svg'
import logoTeal from '../assets/logo/logo_teal.svg'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'
export default class Navbar extends Component {
  state = {
    active: false,
  }

  scrollToTop = () => {
    scroll.scrollToTop()
  }

  toggleClass = () => {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <Link to={`/`}>
            <img
              src={logo}
              className="nav-logo"
              alt="Logo"
              onMouseOver={e => (e.target.src = logoTeal)}
              onMouseOut={e => (e.target.src = logo)}
              onClick={this.scrollToTop}
            />
          </Link>
          {/* Beginning of mobile nav components */}
          <button
            id="burger"
            className={
              'hamburger hamburger--spin' +
              (this.state.active ? ' is-active' : '')
            }
            type="button"
            onClick={this.toggleClass}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <ul
            className={
              this.state.active
                ? 'nav-items-burger flex-display '
                : 'nav-items-burger'
            }
          >
            <li className="nav-item-burger">
              <ScrollLink
                onClick={this.toggleClass}
                activeClass="active"
                to="about"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                About
              </ScrollLink>
            </li>
            <li className="nav-item-burger">
              <ScrollLink
                onClick={this.toggleClass}
                activeClass="active"
                to="work"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                Work
              </ScrollLink>
            </li>
            <li className="nav-item-burger">
              <ScrollLink
                onClick={this.toggleClass}
                activeClass="active"
                to="contact"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                Contact
              </ScrollLink>
            </li>
          </ul>
          {/* end of mobile nav components */}
          <ul className="nav-items">
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="about"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                About
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="work"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                Work
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="contact"
                spy
                smooth
                offset={-70}
                duration={500}
              >
                Contact
              </ScrollLink>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}
