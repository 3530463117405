import React from 'react'
import Section from './Section'
import Footer from './sections/Footer'
import About from './sections/About'
import Work from './sections/Work'
import Contact from './sections/Contact'
import Navbar from './Navbar'

export default () => {
  return (
    <div>
      <Navbar />
      <Section title="About" copy={<About />} dark id="about" />
      <Section title="Work" copy={<Work />} dark={false} id="work" />
      <Section title="Contact" copy={<Contact />} dark id="contact" />
      <Footer />
    </div>
  )
}
