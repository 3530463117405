import React, { Component } from 'react'
import AboutMore from './AboutMore'

export default class AboutText extends Component {
  state = { visible: false }

  toggleVisible = () => {
    let toggle = this.state.visible === false
    this.setState({ visible: toggle })
  }

  render() {
    return (
      <>
        <p>
          Hello, I'm Mike Wong – a New York based Front-End Software Engineer with a
          background in Graphic Design. I feel strongly about creating seamless
          user experiences, accessible user interfaces, following best practices, strong team collaboration, and writing human readable code for brevity and scalability.
        </p>
        <p
          className={'learnMore ' + (this.state.visible ? 'plus' : 'minus')}
          onClick={this.toggleVisible}
        >
          ...
        </p>
        {this.state.visible ? <AboutMore /> : null}
      </>
    )
  }
}
