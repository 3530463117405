import React from 'react'
import { Link } from 'react-router-dom'
import projects from './projects.json'

export default () => {
  return (
    <div className="works-list">
      {projects.map(project => {
        const { name, description } = project
        return (
          <figure key={name} className={name}>
            <Link to={`/work/${name.toLowerCase()}`}>
              <p className="title">{name}</p>
              <div
                className={`${name.toLowerCase()}-thumbnail thumbnail`}
                alt={name}
              >
                <p className="description">{description}</p>
              </div>
            </Link>
          </figure>
        )
      })}
    </div>
  )
}
