import React from 'react'
// import ss_video from '../../../assets/videos/ss_video.m4v'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <figure className='project-section'>
        <p>Try live demo or visit github.</p>{' '}
        <VisitButtons
          demo={'http://cinefind.netlify.com'}
          github={'https://github.com/mikekwong/cinefind'}
        />
        {/* <video
          src={ss_video}
          type="video/mp4s"
          controls
          controlsList="nodownload"
        /> */}
      </figure>
    </>
  )
}
