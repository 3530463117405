import React from 'react'
import ngagement from '../../../assets/videos/ngagement.mp4'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <figure className="project-section">
        <p>Visit the website or watch a video demo of the mobile view below.</p>
        <VisitButtons demo={'http://n-gage.co'} />
        <video
          src={ngagement}
          type="video/mp4s"
          autoplay
          loop
          controls
          controlsList="nodownload"
        />
      </figure>
    </>
  )
}
