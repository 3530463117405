import React, { Component } from 'react'
import mike from '../../assets/images/mike.jpg'

export default class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <img src={mike} className="mike" alt="mike" />
        <div className="inTouch">
          <p>Get in touch.</p>
          <a alt="email" href="mailto:mike@mikewong.dev">
            <ion-icon id="email" name="mail" />
          </a>
          <a alt="linkedin" href="https://linkedin.com/in/wong-mike">
            <ion-icon id="linkedin" name="logo-linkedin" />
          </a>
          <a alt="github" href="https://github.com/mikekwong">
            <ion-icon id="github" name="logo-octocat" />
          </a>
          <a
            alt="medium"
            href="https://medium.com/@mike_wong/what-is-memoization-in-javascript-5c6cdef49ad2"
          >
            <ion-icon id="medium" name="paper" />
            {/* <ion-icon id='medium' name='paper' /> */}
          </a>
          {/* <a alt="resume" href="/mike_wong_resume.pdf">
            <ion-icon id="resume" name="document" />
          </a> */}
        </div>
      </div>
    )
  }
}
