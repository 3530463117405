import React from 'react'

export default ({ title, copy, dark, id }) => {
  return (
    <section className={'section' + (dark ? ' section-dark' : '')}>
      <div className="section-content" id={id}>
        <h2>{title}</h2>
        <br />
        {copy}
      </div>
    </section>
  )
}
