import React from 'react'

export default () => {
  return (
    <>
      <br />
      <p>
        I'm currently an Front-end Developer at Capgemini. There, I work on a range of web apps and websites for one of their clients, a major financial institution. In my role, I collaborate with Project Managers, Designers, QA, Dev-Ops, and other Developers in order build out fully functional user interfaces that meet web standards and are web accessible by incorporating the best user experience possible.
      </p>
      <br />
      <br />
      <p>
        When I'm not coding, you'll find me traveling, enjoying seasonal pastimes like skiing and hiking, engaging in mixology, as well as cooking. Occasionally, you might find me perusing certain tech blogs / books.
      </p>
      <br />
      <br />
      {/* <p>
        Currently I'm picking up Python as a new language to add to my
        repertoire. With machine learning becoming more prevalent, it's hard to
        ignore Python's significance in the field.
      </p> */}
    </>
  )
}
