import React from 'react'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <p>
        A crowd-sourced platform that enables users to enrich their musical
        experiences by discovering and sharing songs with others through user
        curated live music channels.
      </p>
      <ul className="bullets">
        <li>
          <h2>ROLE</h2>
          <p>Software Engineer</p>
        </li>
        <li>
          <h2>TECHNOLOGY</h2>
          <p>
            React, React-Router, Redux, CSS, Node.js, Express.js, PostgreSQL,
            OAuth, Socket.io, UIKit, Spotify API.
          </p>
        </li>
        <li>
          <h2>TYPE</h2>
          <p>Web App</p>
        </li>
      </ul>
      <VisitButtons
        demo={'http://soundspace-fsa.herokuapp.com'}
        github={'https://github.com/sound-space/SoundSpace'}
      />
    </>
  )
}
