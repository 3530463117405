import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from './Home'
import Cinefind from './work/cinefind'
import SoundSpace from './work/soundspace'
import Stackathon from './work/stackathon'
import Ngage from './work/ngage'
import Prsm from './work/prsm'
// import Hypnode from './work/hypnode'
import NotFound from './NotFound'

export default () => {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/work/cinefind" component={Cinefind} />
          <Route exact path="/work/soundspace" component={SoundSpace} />
          <Route exact path="/work/stackathon" component={Stackathon} />
          <Route exact path="/work/ngage" component={Ngage} />
          <Route exact path="/work/prsm" component={Prsm} />
          {/* <Route exact path="/work/hypnode" component={Hypnode} /> */}
          <Route component={NotFound} />
        </Switch>
    </BrowserRouter>
  )
}
