import React from 'react'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <p>
        PRSM Photography is a small independent studio that specializes in
        still-life, portrait-subjects, landscape, and wildlife photographs. Its
        craft is as much a hobby as it is a business.
      </p>
      <ul className='bullets'>
        <li>
          <h2>ROLE</h2>
          <p>Front-End Developer</p>
        </li>
        <li>
          <h2>TECHNOLOGY</h2>
          <p>HTML, CSS, Javascript</p>
        </li>
        <li>
          <h2>TYPE</h2>
          <p>Responsive Website</p>
        </li>
      </ul>
      <VisitButtons demo={'http://prsmphotography.com'} />
    </>
  )
}
