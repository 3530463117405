import React from 'react'

export default () => {
  return (
    <div className="page-container">
      <h1 className="title">404</h1>
      <div className="visit goHome">
        <a href="/">
          <button>Go Home</button>
        </a>
      </div>
    </div>
  )
}
