import React from 'react'
import intro from '../../../assets/images/prsm/intro.png'
import main from '../../../assets/images/prsm/main.png'
import panel from '../../../assets/images/prsm/panel.png'
import thumbnail from '../../../assets/images/prsm/thumbnail.png'
import bird from '../../../assets/images/prsm/bird.png'

export default () => {
  return (
    <>
      <figure className="project-section">
        <p>
          The design and development goal for this project was to establish the
          logo and website with an overall minimalist appearance in order to
          give it a clean and edgy feel (echoing a similar sentiment about the
          professional photography industry). The logo needed to be simple,
          uniform, playful, and easy to read from left-right to top-down. This
          helps to express the notion of how photographers interact with their
          subjects and how art interacts with their viewer by encouraging eye
          movement through the viewers gaze.
        </p>

        <img src={intro} alt="Intro Page" />
      </figure>
      <figure className="project-section">
        <p>
          The fluid interactive navigation process includes icons and panels,
          all of which are consistent throughout all works ranging from
          thumbnails, to large format, to slideshow views. The mobile and
          desktop versions needed to be both dynamic and similar, yet distinct.
          The two distinct versions of the site aim to recognize the limitations
          and maximum potential of varying screen sizes to create the best
          possible experience for the end-user in mind. At the same time, the
          similarities help foster brand recognition.
        </p>
        <img src={main} alt="Main Page" />
      </figure>
      <figure className="project-section">
        <p>
          All the panels were built using flexbox containers to track width
          changes and adapt accordingly whenever click event happens on any of
          them. The unselected categories have their names rotate as well to
          accommodate these changes. You can change the view to the next or
          previous image, play a slideshow, or go to thumbnail view to see the
          catalog.
        </p>
        <img src={panel} alt="Panel View" />
      </figure>
      <figure className="project-section">
        <p>
          By selecting any image in the thumbnail view, it'll take you back to
          the main page with that new image in view as a main panel.
        </p>
        <img src={thumbnail} alt="Thumbnail View" />
        <img src={bird} alt="Bird View" />
      </figure>
    </>
  )
}
