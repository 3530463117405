import React from 'react'
import main from '../../../assets/images/ngage/main.jpg'
import devices from '../../../assets/images/ngage/devices.jpg'

export default () => {
  return (
    <>
      <figure className="project-section">
        <p>
          The form, function, and specific aesthetic feel of the website is
          targeted toward the 20-30 something age group and particularly women.
          Its purpose is to inform the audience the niche market for this
          specialized product, including its capabilities. As so, it is catered
          to their specific underlying need to organize and simplify their most
          frequented connections. Particularly in today's world with the speedy
          turnaround in the competitive services industry.
        </p>
        <p>
          The design and development goal for this project was to establish the
          logo and website with a modest appearance and simplified hierarchy of
          information flow. The website's main objective is to market and
          advertise the product, especially its capabilities. The color themes
          also needed to stay consistent with the app itself, in order to
          maintain a cohesive branding identity across all areas. The logo
          needed to be simple, legible as an 'N', and serve as a symbol for
          network link, and lastly as a suitable icon for the app itself. As the
          name of the product pronounces similarly to the word 'engage', it can
          be thought of as a verb. Because of that, it was of high importance to
          have the tagline be a continuation of that dialogue in the form of an
          exciting action. Yet at the same time, describe the product itself in
          as few words as possible. This in turn enables the audience to make a
          quick general hypothesis about the product, before learning more.
        </p>
        <img src={devices} alt="devices Page" />
      </figure>
      <figure className="project-section">
        <p>
          Main part of landing page with Hero and CTA with preview of product
          use case on iOS.
        </p>
        <img src={main} alt="Main View" />
      </figure>
    </>
  )
}
