import React from 'react'
import menu from '../../../assets/images/stackathon/menu.png'
import start from '../../../assets/images/stackathon/start.png'
import stacking from '../../../assets/images/stackathon/stacking.png'
import won from '../../../assets/images/stackathon/won.png'
import lost from '../../../assets/images/stackathon/lost.png'

export default () => {
  return (
    <>
      {/* <h2 className="case-study">Case Study</h2> */}
      <figure className="project-section-stackathon">
        <p>
          The game was created as a proof of concept that it's possible to use
          React Native to make mobile games, opposite of what it was intended to
          do which was to just make mobile apps. I planned for the game to be as
          simple and easy to play as possible with zero to minimal instruction
          and no learning curve.
        </p>
        <p>
          The core libraries that do most of the heavy lifting is MatterJS for
          it's 2d physics library which control shapes, physical bodies, and
          friction. And RNGE which is an Entity Component System that separates
          and organizes game logic from visual properties. This is drastic
          departure from traditional OOP architecture – which is more class and
          instance based where the majority of game frameworks are based on. The
          view layer, frame and state changes are tracked by React Native.
        </p>
        <img src={menu} alt="Menu Screen" />
      </figure>
      <figure className="project-section-stackathon">
        <p>
          You have a certain number of lives depending on the difficulty and the
          goal is to stack the blocks as high as possible either from the top,
          from between blocks, and move each around to stay balanced.
        </p>
        <img src={start} alt="Start Screen" />
        <img className="stack-margin" src={stacking} alt="Stacking Screen" />
      </figure>
      <figure className="project-section-stackathon">
        <p>
          Once you're able to stack the blocks to the top and add the key piece,
          you'll win. Otherwise you lose either by dropping the key piece or if
          you've lost too many lives (blocks).
        </p>
        <img src={won} alt="Won Screen" />
        <img className="stack-margin" src={lost} alt="Lost Screen" />
      </figure>
    </>
  )
}
