import React from 'react'
import ss_main from '../../../assets/images/soundspace/ss_main.png'
import ss_form from '../../../assets/images/soundspace/ss_form.png'
import ss_channel from '../../../assets/images/soundspace/ss_channel.png'

export default () => {
  return (
    <>
      {/* <h2 className="case-study">Case Study</h2> */}
      <figure className="project-section">
        <p>
          The core functionality of the app is based around the Spotify API and
          its recommendation API. The design architecture had to be planned
          around it based on user login, searchable songs, adding songs to the
          queue, and channel music being played as others join the channel.
        </p>
        <p>
          On the main page of the app, users can create, search, or join
          channels. React was used as the MVC and Redux for its modular state
          management allowing the platform to become scalable for future
          features. Channel searches are easily queried through a combination of
          local and global states by filtering keywords in real-time from the
          PostgreSQL database. CSS and the UIKit framework improved user
          experienced for its modern UI appearance and helped decrease overall
          development time.
        </p>
        <img src={ss_main} alt="Main Page" />
      </figure>
      <figure className="project-section">
        <p>
          Once the form's input fields are filled, it interacts with the
          database again to create a new channel with at least one added track
          from Spotify's API.
        </p>
        <img src={ss_form} alt="Channel Form" />
      </figure>
      <figure className="project-section">
        <p>
          The channel view offers details such as song, artist, and album. It
          also keeps track of how many concurrent users are currently in the
          room that you can chat with via the socket.io library. The visualizer
          bars change based on the beats of the song.
        </p>
        <img src={ss_channel} alt="Channel View" />
      </figure>
    </>
  )
}
