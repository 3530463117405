import React from 'react'
import ss_video from '../../../assets/videos/ss_video.m4v'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <figure className='project-section'>
        <p>Live demo or watch the video demo of the project below.</p>
        <VisitButtons
          demo={'http://soundspace-fsa.herokuapp.com'}
          github={'https://github.com/sound-space/SoundSpace'}
        />
        <video
          src={ss_video}
          type='video/mp4s'
          controls
          controlsList='nodownload'
        />
      </figure>
    </>
  )
}
