import React from 'react'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <p>
        A movie finder platform you can use to discover movies old and new, look
        up new releases, popular movies, and top rated movies.
      </p>
      <ul className="bullets">
        <li>
          <h2>ROLE</h2>
          <p>Front-End Engineer</p>
        </li>
        <li>
          <h2>TECHNOLOGY</h2>
          <p>
            React, React-Router, CSS/Sass, React-Coverflow, Axios, PostCSS, Node.js, TMDb API.
          </p>
        </li>
        <li>
          <h2>TYPE</h2>
          <p>Responsive Web App</p>
        </li>
      </ul>
      <VisitButtons
        demo={'http://cinefind.netlify.com'}
        github={'https://github.com/mikekwong/cinefind'}
      />
    </>
  )
}
