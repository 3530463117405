import React from 'react'
import prsm_desktop from '../../../assets/videos/prsm_desktop.m4v'
import prsm_mobile from '../../../assets/videos/prsm_mobile.m4v'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <figure className="project-section">
        <p>
          Visit the website or watch a video demo of desktop and mobile below.
        </p>
        <VisitButtons demo={'http://prsmphotography.com'} />
        <video
          src={prsm_desktop}
          type="video/mp4s"
          controls
          controlsList="nodownload"
        />
        <video
          style={{ marginTop: '20px', width: '50%' }}
          src={prsm_mobile}
          type="video/mp4s"
          controls
          controlsList="nodownload"
        />
      </figure>
    </>
  )
}
