import React from 'react'
import VisitButtons from '../VisitButtons'

export default () => {
  return (
    <>
      <p>
        NGAGE is an up and coming mobile app that enables users to categorize,
        organize, filter, take notes, and communicate with their every other day
        connections. Allowing them to create their own circle of individuals
        whom offer quality services and goods, yet doesn't wallow in the common
        irritations of social networking.
      </p>
      <ul className='bullets'>
        <li>
          <h2>ROLE</h2>
          <p>Front-End Developer</p>
        </li>
        <li>
          <h2>TECHNOLOGY</h2>
          <p>HTML, CSS, Javascript, VelocityJS</p>
        </li>
        <li>
          <h2>TYPE</h2>
          <p>Landing Page</p>
        </li>
      </ul>
      <VisitButtons demo={'http://n-gage.co'} />
    </>
  )
}
