import React from 'react'

export default ({ navLeft, navRight }) => {
  return (
    <>
      <a href={navLeft}>
        <ion-icon id="left" name="ios-arrow-back" />
      </a>
      <a href={navRight}>
        <ion-icon id="right" name="ios-arrow-forward" />
      </a>
    </>
  )
}
