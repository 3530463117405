import React from 'react'

export default ({ demo, github }) => {
  return (
    <div className="visit">
      <a href={demo}>
        {/* for projects with no repo */}
        <button>{!github ? 'Live Website' : 'Live Demo'}</button>
      </a>
      {/* for projects with no repo */}
      {github && (
        <a href={github}>
          <button>Github</button>
        </a>
      )}
    </div>
  )
}
