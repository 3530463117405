import React from "react";

export default () => {
  return (
    <>
      <footer className="footer">
        <p>Custom made with React and Sass.</p>
        <p>© Mike Wong {new Date().getFullYear()}.</p>
        {/* <p>
          © Mike Wong {new Date().getFullYear()}. Made with{' '}
          <ion-icon name='heart' id='heart' /> in NYC.
        </p> */}
      </footer>
    </>
  );
};
